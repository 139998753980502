<template lang="pug">
validation-observer.w-100(ref="editLeaseGeneralForm")
  div.px-1.w-100
    .d-flex.pt-1.w-100
      .d-flex.flex-column.w-100.mr-1
        b-form-group(label='Code *' label-for='codeCRO' style='flex:1')
          validation-provider(#default='{ errors }' name='codeCRO' rules="required")
            ejs-textbox(id="codeCRO" :enabled="false" v-model="leaseForm.codeCRO" name="codeCRO")
            small.text-danger {{ errors[0] }}
      .d-flex.flex-column.w-100.mr-1
        b-form-group(label='Nom de dossier *' label-for='label')
          validation-provider(#default='{ errors }' name='Nom du dossier' rules="required")
            ejs-textbox(id="affairName" :enabled="false" v-model="leaseForm.label" name="Nom")
            small.text-danger {{ errors[0] }}
      .d-flex.flex-column.w-100
        b-form-group(label='Statut' label-for='affairStatus')
          validation-provider(#default='{ errors }' name='affairStatus')
            ejs-dropdownlist(id='affairStatus' popupHeight='300px' filterType='Contains' :allowFiltering='true' :dataSource='statusList' :fields="{ text: 'label', value: 'value' }" v-model='leaseForm.status')
  div.px-1.w-100
    .d-flex.flex-column.pt-1.w-100
      h3 Adresse du bien
      .d-flex.flex-column.w-100
        b-form-group.pt-1
          validation-provider(#default='{ errors }' name='propertyAddress')
            vue-typeahead-bootstrap#propertyAddress.not-autocomplete(type='text' :class="errors.length > 0 ? 'is-invalid' : ''" :maxMatches="100" :minMatchingChars="2" :showAllResults="true" :state='errors.length > 0 ? false : null' name="propertyAddress" v-model='leaseForm.propertyAddress' :data='autocompletePropertyData' :serializer='item => item.properties.label'  placeholder='Rechercher une adresse ou saisissez : Rue et n° de rue' @input='autocompleteAddressSource()' @hit='autocompleteAddressSelected' autocomplete='nope' aria-autocomplete='nope')
              template(#prepend)
                b-input-group-text.py-0.bg-gray
                  span.material-icons-outlined.text-primary place
              template(slot='suggestion' slot-scope='{ data, htmlText }')
                .item
                  small {{data.properties.name}}
                  span {{data.properties.postcode+ ' ' + data.properties.city}}
      validation-provider.pt-1(#default='{ errors }' name='propertyAddressComplement')
        ejs-textbox(id="propertyAddressComplement" placeholder="Batiment, Appartement.." v-model="leaseForm.propertyAddressComplement" name="propertyAddressComplement")
      validation-provider.pt-1(#default='{ errors }' name='propertyZipCode')
        ejs-textbox(id="propertyZipCode" placeholder="Code postal" v-model="leaseForm.propertyZipCode" name="propertyZipCode")
      validation-provider.pt-1(#default='{ errors }' name='propertyCity')
        ejs-textbox(id="propertyCity" placeholder="Ville" v-model="leaseForm.propertyCity" name="propertyZipCode")
      validation-provider.pt-1(#default='{ errors }' name='propertyCountry')
        ejs-textbox(id="propertyCountry" placeholder="Pays" v-model="leaseForm.propertyCountry" name="propertyCountry")
    b-col(cols='12' style='padding-bottom:0px !important')
      small.cursor-pointer.text-primary(@click="displayGps = !displayGps") {{ displayGps ? '- Masquer les coordonnées GPS' : '+ Editer les coordonnées GPS' }}
  div.px-1.w-100(v-if="displayGps")
    b-col(cols='12' style='padding-bottom:0px !important')
      label(for="address'") Coordonnées GPS
    b-col(cols='6' style='padding-bottom:0px !important')
      validation-provider(name='latitude')
        ejs-textbox(id="latitude" placeholder="Latitude" v-model="leaseForm.propertyLatitude" name="propertyLatitude")
    b-col(cols='6' style='padding-bottom:0px !important')
      validation-provider(name='longitude')
        ejs-textbox(id="longitude" placeholder="Longitude" v-model="leaseForm.propertyLongitude" name="propertyLongitude")
  div.px-1.w-100.flex-column.pt-1
    label(label-for='address' style='font-weight: 600;') Description du bien
    .d-flex.w-100.inputs-wrapper
      validation-provider.mr-1(#default='{ errors }' name='propertyType' rules="required")
        label(label-for='propertyType') Type de bien *
        ejs-dropdownlist(id='propertyType' :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource='propertyTypeList' :fields="{ text: 'label', value: 'id' }" v-model='leaseForm.propertyTypeId')
          template(v-slot:footerTemplate)
            div.p-50
              ejs-button(v-on:click.native="newPropertyType" :content="'+ Nouveau type de bien'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
        small.text-danger {{ errors[0] }}
      validation-provider.mr-1(name='propertyArea')
        label(label-for='propertyArea')
          | Surface
        ejs-numerictextbox(id="propertyArea" format="########.## m²" v-model="leaseForm.propertyArea")
      validation-provider(name='prorataShare')
        label(label-for='prorataShare')
          | Quote-part
        ejs-numerictextbox(id="prorataShare" :step="0.001" minimumFractionDigits="3" maximumFractionDigits="3" format='n3' v-model="leaseForm.prorataShare")
  vs-divider.mb-50.mt-1(color='#cccccc')
  b-row.m-0
    b-col.py-0(cols='12')
      h3 Bailleur / Propriétaire du bien
      b-form-group
        label(label-for='searchLandlord' style='font-weight: 600;') Société / Particulier
        validation-provider(#default='{ errors }' name='searchLandlord')
          vue-typeahead-bootstrap#searchLandlord.not-autocomplete(type='text' disabled inputClass='bg-light-primary' :class="errors.length > 0 ? 'is-invalid' : ''" :maxMatches="100" :minMatchingChars="3" v-model='leaseForm.landlordName' :data='autocompleteLandlordData' :serializer="item => item.companyAccount? item.name+' / '+item.companyAccount : item.name" :screen-reader-text-serializer="item => item.name" placeholder='Rechercher par nom ou code analytique' autocomplete='nope' aria-autocomplete='nope' style="cursor: not-allowed;")
            template(#prepend)
              b-input-group-text.px-50.py-0.bg-gray
                span.material-icons-outlined.text-primary business
            template(slot='suggestion' slot-scope='{ data, htmlText }')
              .item
                div
                  span {{data.name}}
                  img.rounded(:src="require('@/assets/images/logo/logo-'+data.source+'.png')" style="height:20px; float:right; right:10px; border-radius:5px; padding:0px 5px" v-if="data.source && data.source!=='orisis'")
                  span.rounded.bg-transparent.small(v-else :class="data.id>0 ? 'border-primary bg-primary text-white' : 'border-primary bg-white text-primary' " style="float:right; right:10px; padding:0px 5px") {{data.id>0 ? "Orisis" : data.source}}
                small(v-if="data.addresses && data.addresses.length>0")
                  | {{data.addresses[0].address}} - {{data.addresses[0].zipCode+ ' ' + data.addresses[0].city}}
  div
    b-row.m-0
      b-col(cols='12' style='padding-bottom:0px !important')
        label(label-for='address' style='font-weight: 600;') Adresse
        b-form-group
          validation-provider(#default='{ errors }' name='landlordAddress')
            ejs-textbox(id="landlordAddress" disabled placeholder="Rue et n° de rue" v-model="leaseForm.landlordAddress" name="landlordAddress")
      b-col(cols='12' style='padding-bottom:0px !important')
        validation-provider(#default='{ errors }' name='landlordAddressComplement')
          ejs-textbox(id="landlordAddressComplement" disabled placeholder="Batiment, Appartement.." v-model="leaseForm.landlordAddressComplement" name="landlordAddressComplement")
    b-row.m-0
      b-col(cols='3' style='padding-bottom:0px !important')
        validation-provider(#default='{ errors }' name='landlordZipCode')
          ejs-textbox(id="landlordZipCode" disabled placeholder="Code postal" v-model="leaseForm.landlordZipCode" name="landlordZipCode")
      b-col(cols='9' style='padding-bottom:0px !important')
        validation-provider(#default='{ errors }' name='landlordCity')
          ejs-textbox(id="landlordCity" disabled placeholder="Ville" v-model="leaseForm.landlordCity" name="landlordCity")
    b-row.m-0
      b-col(cols='12' style='padding-bottom:0px !important')
        validation-provider(#default='{ errors }' name='landlordCountry')
          ejs-textbox(id="landlordCountry" disabled placeholder="Pays" v-model="leaseForm.landlordCountry" name="landlordCountry")
  .d-flex.w-100.flex-column.px-1.pt-1
    validation-provider.pt-1(#default='{ errors }' name='searchLandlordContact')
      label(label-for='searchLandlordContact' style='font-weight: 600;') Contact principal
      vue-typeahead-bootstrap#searchLandlordContact.not-autocomplete(type='text' :class="errors.length > 0 ? 'is-invalid' : ''" :maxMatches="100" :minMatchingChars="3" :state='errors.length > 0 ? false : null' inputClass='bg-light-primary' v-model='searchLandlordContact' :data='autocompleteLandlordContactData' :serializer="item => item.firstName + ' ' + item.lastName" placeholder='Rechercher par nom ou code analytique' @input='autocompleteLandlordContact()' @hit='autocompleteLandlordContactSelected' autocomplete='nope' aria-autocomplete='nope')
        template(#prepend)
          b-input-group-text.px-50.py-0.bg-gray
            span.material-icons-outlined.text-primary person
        template(slot='suggestion' slot-scope='{ data, htmlText }')
          .item
            div
              span {{ data.id > 0 ? data.displayLabel : data.firstName + ' ' + data.lastName}}
              span.rounded.bg-transparent.small(:class=" data.id>0 ? 'border-primary bg-primary text-white' : 'border-primary bg-white text-primary'" style="float:right; right:10px; padding:0px 5px") {{data.id>0 ? "Orisis" : data.source}}
            small
              | {{ data.email ? data.email : ''}} {{ data.email && data.phoneNumber ? ' / '+ data.phoneNumber :  data.phoneNumber ? data.phoneNumber : '' }}
    .d-flex.w-100.inputs-wrapper
      validation-provider.mr-1.pt-1.w-100(#default='{ errors }' name='landlordContactCivility')
        ejs-dropdownlist(id='landlordContactCivility' placeholder="Civilité" popupHeight='300px' :allowFiltering='true' :dataSource='optionCivility' :fields="{ text: 'label', value: 'value' }" v-model='leaseForm.landlordContactCivility')
      validation-provider.mr-1.pt-1.w-100(#default='{ errors }' name='landlordContactLastName')
        ejs-textbox(id="landlordContactLastName" placeholder="Nom" v-model="leaseForm.landlordContactLastName" name="landlordContactLastName")
      validation-provider.pt-1.mr-1.w-100(#default='{ errors }' name='landlordContactFirstName')
        ejs-textbox(id="landlordContactFirstName" placeholder="Prénom" v-model="leaseForm.landlordContactFirstName" name="landlordContactFirstName")
      b-form-group.pt-1.mr-1.w-100(label-for='landlordContactPhone')
        ejs-textbox(id="landlordContactPhone" placeholder="Téléphone" v-model="leaseForm.landlordContactPhone" name="landlordContactPhone")
      b-form-group.pt-1.w-100(label-for='landlordContactEmail')
        validation-provider(#default='{ errors }' name='landlordContactEmail' rules='email')
          ejs-textbox(id="landlordContactEmail" placeholder="Adresse e-mail" v-model="leaseForm.landlordContactEmail" name="landlordContactEmail")
          small.text-danger {{ errors[0] }}
  vs-divider.mb-50.mt-1(color='#cccccc')
  b-row.m-0
    b-col(cols='12' style='padding-bottom:0px !important')
      h3 Locataire du bien
      b-form-group.mb-1
        ejs-checkbox(label='Particulier ?' v-model="leaseForm.tenantIsParticular" @change="resetTenant")
      b-form-group
        label(label-for='tenantName' style='font-weight: 600;') {{ leaseForm.tenantIsParticular ? 'Particulier' : 'Société *' }}
        validation-provider(#default='{ errors }' name='tenantName' :rules="!leaseForm.tenantIsParticular ? 'required' :(!leaseForm.tenantCivility || !leaseForm.tenantLastName ? 'required' : '')")
          vue-typeahead-bootstrap#tenantName.not-autocomplete(type='text' inputClass='bg-light-primary' :maxMatches="100" :minMatchingChars="3" v-model='leaseForm.tenantName' :data='autocompleteTenantData' :serializer="item => item.companyAccount? item.name+' / '+item.companyAccount : item.name" :screen-reader-text-serializer="item => item.name" placeholder='Rechercher par nom ou code analytique' @input='autocompleteTenant()' @hit='autocompleteTenantSelected' autocomplete='nope' aria-autocomplete='nope' style="cursor: not-allowed !important;")
            template(#prepend)
              b-input-group-text.px-50.py-0.bg-gray
                  span.material-icons-outlined.text-primary {{ leaseForm.tenantIsParticular ? 'person' : 'business'}}
            template(slot='suggestion' slot-scope='{ data, htmlText }')
              .item
                div
                  span {{data.name}}
                  img.rounded(:src="require('@/assets/images/logo/logo-'+data.source+'.png')" style="height:20px; float:right; right:10px; border-radius:5px; padding:0px 5px" v-if="data.source && data.source!=='orisis'")
                  span.rounded.bg-transparent.small(v-else :class="data.id>0 ? 'border-primary bg-primary text-white' : 'border-primary bg-white text-primary' " style="float:right; right:10px; padding:0px 5px") {{data.id>0 ? "Orisis" : data.source}}
                small(v-if="data.addresses && data.addresses.length>0")
                  | {{data.addresses[0].address}} - {{data.addresses[0].zipCode+ ' ' + data.addresses[0].city}}
          small.text-danger(v-if="errors[0]") {{ errors[0] + (leaseForm.tenantIsParticular ? " ou renseignez un nouveau locataire" : '') }}
  b-row.m-0(v-if="leaseForm.tenantIsParticular")
    b-col(cols='4' style='padding-bottom:0px !important')
      b-form-group
        label(label-for='tenantCivility' style='font-weight: 600;') Civilité *
        validation-provider(#default='{ errors }' name='tenantCivility' rules="required")
          ejs-dropdownlist(id='tenantCivility' placeholder="Civilité" popupHeight='300px' :allowFiltering='true' :dataSource='optionCivility' :fields="{ text: 'label', value: 'value' }" v-model='leaseForm.tenantCivility' @input="leaseForm.tenantIsParticular ? leaseForm.tenantName = civilityTranslate(leaseForm.tenantCivility) + (leaseForm.tenantFirstName ? (leaseForm.tenantFirstName + ' ') : '') + (leaseForm.tenantLastName ? leaseForm.tenantLastName : '') : ''")
          small.text-danger {{ errors[0] }}
    b-col(cols='4' style='padding-bottom:0px !important')
      b-form-group
        label(label-for='tenantFirstName' style='font-weight: 600;') Prénom
        validation-provider(#default='{ errors }' name='tenantFirstName')
          ejs-textbox(id="tenantFirstName" placeholder="Prénom" v-model="leaseForm.tenantFirstName" name="tenantFirstName" @input="leaseForm.tenantIsParticular ? leaseForm.tenantName = civilityTranslate(leaseForm.tenantCivility) + (leaseForm.tenantFirstName ? (leaseForm.tenantFirstName + ' ') : '') + (leaseForm.tenantLastName ? leaseForm.tenantLastName : '') : ''")
    b-col(cols='4' style='padding-bottom:0px !important')
      b-form-group
        label(label-for='tenantLastName' style='font-weight: 600;') Nom *
        validation-provider(#default='{ errors }' name='tenantLastName' rules="required")
          ejs-textbox(id="tenantLastName" placeholder="Nom" v-model="leaseForm.tenantLastName" name="tenantFirstName" @input="leaseForm.tenantIsParticular ? leaseForm.tenantName = civilityTranslate(leaseForm.tenantCivility) + (leaseForm.tenantFirstName ? (leaseForm.tenantFirstName + ' ') : '') + (leaseForm.tenantLastName ? leaseForm.tenantLastName : '') : ''")
          small.text-danger {{ errors[0] }}
  .d-flex.w-100.flex-column.px-1.pt-1(v-if="!leaseForm.tenantIsParticular")
    validation-provider(#default='{ errors }' name='searchTenantContact')
      label(label-for='searchTenantContact' style='font-weight: 600;') Contact principal
      vue-typeahead-bootstrap#searchTenantContact.not-autocomplete(type='text' :class="errors.length > 0 ? 'is-invalid' : ''" :maxMatches="100" :minMatchingChars="3" :state='errors.length > 0 ? false : null' inputClass='bg-light-primary' v-model='searchTenantContact' :data='autocompleteTenantContactData' :serializer="item => item.firstName + ' ' + item.lastName" placeholder='Rechercher par nom ou code analytique' @input='autocompleteTenantContact()' @hit='autocompleteTenantContactSelected' autocomplete='nope' aria-autocomplete='nope')
        template(#prepend)
          b-input-group-text.px-50.py-0.bg-gray
            span.material-icons-outlined.text-primary person
        template(slot='suggestion' slot-scope='{ data, htmlText }')
          .item
            div
              span {{data.id > 0 ? data.displayLabel : data.lastName+' '+data.firstName}}
              span.rounded.bg-transparent.small( :class="data.id>0 ? 'border-primary bg-primary text-white' : 'border-primary bg-white text-primary'" style="float:right; right:10px; padding:0px 5px") {{data.id>0 ? "Orisis" : data.source}}
            small
              | {{ data.email ? data.email : ''}} {{ data.email && data.phoneNumber ? ' / '+ data.phoneNumber :  data.phoneNumber ? data.phoneNumber : '' }}
    .d-flex.w-100.inputs-wrapper
        validation-provider.mr-1.pt-1.w-100(#default='{ errors }' name='tenantContactCivility')
          ejs-dropdownlist(id='tenantContactCivility' placeholder="Civilité" popupHeight='300px' :allowFiltering='true' :dataSource='optionCivility' :fields="{ text: 'label', value: 'value' }" v-model='leaseForm.tenantContactCivility')
        validation-provider.pt-1.mr-1.w-100(#default='{ errors }' name='tenantContactLastName')
          ejs-textbox(id="tenantContactLastName" placeholder="Nom" v-model="leaseForm.tenantContactLastName" name="tenantContactLastName")
        validation-provider.pt-1.mr-1.w-100(#default='{ errors }' name='tenantContactFirstName')
          ejs-textbox(id="tenantContactFirstName" placeholder="Prénom" v-model="leaseForm.tenantContactFirstName" name="tenantContactFirstName")
        b-form-group.pt-1.mr-1.w-100(label-for='tenantContactPhone')
          ejs-textbox(id="tenantContactPhone" placeholder="Téléphone" v-model="leaseForm.tenantContactPhone" name="tenantContactPhone")
        validation-provider.pt-1.w-100(#default='{ errors }' name='tenantContactEmail' rules='email')
          ejs-textbox(id="tenantContactEmail" placeholder="Adresse e-mail" v-model="leaseForm.tenantContactEmail" name="tenantContactEmail")
          small.text-danger {{ errors[0] }}
  vs-divider.mb-50.mt-1(color='#cccccc')
  b-row.m-0
    b-col(cols='12' style='padding-bottom:0px !important')
      h3 A propos du bail
      b-form-group
        label(label-for='leaseType' style='font-weight: 600;') Type de bail *
        validation-provider(#default='{ errors }' name='leaseType' rules='required')
          ejs-dropdownlist(id='leaseType' :footerTemplate="'footerTemplate'" placeholder="Type de bail" popupHeight='300px' :allowFiltering='true' :dataSource='leaseTypeList' :fields="{ text: 'label', value: 'id' }" v-model='leaseForm.leaseTypeId')
            template(v-slot:footerTemplate)
              div.p-50
                ejs-button(v-on:click.native="newLeaseType" :content="'+ Nouveau type de bail'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
          small.text-danger {{ errors[0] }}
  b-row.m-0
    b-col(cols='6' style='padding-bottom:0px !important')
      b-form-group
        label(label-for='startDate' style='font-weight: 600;') Début du bail *
        validation-provider(#default='{ errors }' name='startDate' rules='required')
          ejs-datepicker(:format="'dd/MM/yyyy'" locale='fr' v-model="leaseForm.startDate" placeholder="JJ/MM/AAAA" @blur="leaseGroupFunctions({lease: leaseForm})")
          small.text-danger {{ errors[0] }}
    b-col(cols='6' style='padding-bottom:0px !important')
      b-form-group
        label(label-for='endDate' style='font-weight: 600;') Fin du bail *
        validation-provider(#default='{ errors }' name='endDate rules="required"')
          ejs-datepicker(:format="'dd/MM/yyyy'" locale='fr' v-model="leaseForm.endDate" placeholder="JJ/MM/AAAA" @blur="leaseGroupFunctions({lease: leaseForm})")
          small.text-danger {{ errors[0] }}
  .d-flex.w-100.inputs-wrapper.px-1.d-flex.align-items-end
        validation-provider.w-100.pt-1.mr-1(#default='{ errors }' name='periodicity' rules="required")
          label(label-for='periodicity' style='font-weight: 600;') Périodicité *
          ejs-dropdownlist(id='periodicity' :footerTemplate="'footerTemplate'" popupHeight='300px' :allowFiltering='true' :dataSource='periodicitiesList' :fields="{ text: 'label', value: 'id' }" v-model='leaseForm.periodicityId' @select="leaseGroupFunctionsLocal")
            template(v-slot:footerTemplate)
              div.p-50
                ejs-button(v-on:click.native="newPeriodicity" :content="'+ Nouvelle périodicité'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
          small.text-danger {{ errors[0] }}
        validation-provider.w-100.pt-1.mr-1(#default='{ errors }' name='paymentDay')
          label(label-for='paymentDay' style='font-weight: 600;') Jour paiement
          ejs-dropdownlist(id='paymentDay' popupHeight='300px' :allowFiltering='true' :dataSource='paymentDayList' v-model='leaseForm.paymentDay')
        b-form-group.w-100.d-flex.align-items-center(style="height: 32px;")
          b-form-checkbox.checkbox-options(v-model="leaseForm.tacitAgreement")
            | Tacite reconduction
  .d-flex.w-100.inputs-wrapper.px-1.pt-1
    .d-flex.flex-column.w-100.mr-1
      h3 Loyer et Charges annuelles
      .d-flex.w-100
        .d-flex.w-75
          validation-provider.w-100(#default='{ errors }' name='rentHcHt' rules="required")
            label(label-for='rentHcHt' style='font-weight: 600;' ) Loyer hors charges hors taxes *
            ejs-numerictextbox(id="rentHcHt" format="########.## €" v-model="leaseForm.rentHcHt" @blur="leaseGroupFunctions({lease: leaseForm})")
            small.text-danger {{ errors[0] }}
        .d-flex.w-25.ml-1
          validation-provider(#default='{ errors }' name='tvaRentId' rules="required")
            label(label-for='tvaRentId' style='font-weight: 600;') TVA *
            ejs-dropdownlist(id='tvaRentId' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'id' }" :dataSource=`institutionSelected.isSubjectTVA ? referencielTvaOptions : [{label:"0 %", id:5}]` v-model='leaseForm.tvaRentId' @select="leaseGroupFunctions({lease: leaseForm})")
            small.text-danger {{ errors[0] }}
      .d-flex.w-100.pt-1
        b-form-group
          label(label-for='chargesPackage' style='font-weight: 600;') Charges
          b-form-radio-group#chargesPackage(v-model='leaseForm.isChargesPackage' name='chargesPackage' :options='chargesPackageOptions' text-field='label')
      .d-flex.w-100.pt-1
        .d-flex.w-75
          validation-provider.w-100(#default='{ errors }' name='chargeHt')
            label(label-for='chargeHt' style='font-weight: 600;') Montant des charges
            ejs-numerictextbox(id="chargeHt" format="########.## €" v-model="leaseForm.chargeHt" @blur="leaseGroupFunctions({lease: leaseForm})")
        .d-flex.w-25.ml-1
          validation-provider(#default='{ errors }' name='tvaChargeId' :rules="leaseForm.chargeHt ? 'required' : ''")
            label(label-for='tvaChargeId' style='font-weight: 600;') {{ leaseForm.chargeHt ? 'TVA *' : 'TVA' }}
            ejs-dropdownlist(id='tvaChargeId' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'id' }" :dataSource=`institutionSelected.isSubjectTVA ? referencielTvaOptions : [{label:"0 %", id:5}]` v-model='leaseForm.tvaChargeId' @select="leaseGroupFunctions({lease: leaseForm})")
            small.text-danger {{ errors[0] }}
      .d-flex.w-100.py-1
        .d-flex.w-75
          validation-provider.w-100(#default='{ errors }' name='propertyTaxProvisioned')
            label(label-for='propertyTaxProvisioned' style='font-weight: 600;') Taxe foncière de référence
            ejs-numerictextbox(id="propertyTaxProvisioned" format="########.## €" v-model="leaseForm.propertyTaxProvisioned" @blur="leaseGroupFunctions({lease: leaseForm})")
        .d-flex.w-25.ml-1
          validation-provider(#default='{ errors }' name='tvaPropertyTaxId')
            label(label-for='tvaPropertyTaxId' style='font-weight: 600;') {{ leaseForm.propertyTaxProvisioned ? 'TVA *' : 'TVA' }}
            ejs-dropdownlist(id='tvaPropertyTaxId' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'id' }" :dataSource=`institutionSelected.isSubjectTVA ? referencielTvaOptions : [{label:"0 %", id:5}]` v-model='leaseForm.tvaPropertyTaxId' @select="leaseGroupFunctions({lease: leaseForm})")
            small.text-danger {{ errors[0] }}
    .d-flex.flex-column.w-100.bg-light-primary.p-1.rounded.mb-1
        h4 Simulation des totaux d'une quittance
        .d-flex.flex-column.h-100.pt-1(v-if='sumLeaseModel && sumLeaseModel.totalDocument && sumLeaseModel.documentDetails')
          .d-flex.flex-column.h-100
            #simulation-loyer.d-flex.w-100.justify-content-between(v-for="(documentDetail, key) in sumLeaseModel.documentDetails")
              .text-left.flex(v-html="documentDetail.description")
              .text-right {{ formatCurrency(documentDetail.unitPriceHt) }}
          .d-flex.flex-column
            #simulation-charge.d-flex.w-100.justify-content-between.mt-2.border-bottom-secondary
              .text-left.flex Total HT
              .text-right  {{ formatCurrency(sumLeaseModel.totalDocument.totalNetHt) }}
            #simulation-charge.d-flex.w-100.justify-content-between.border-bottom.border-grey(v-for="(item, index) in sumLeaseModel.totalDocument.tvaDetails")
              .text-left.flex TVA {{ referencielTvaTranslate(item.referencielTvaId) }}
              .text-right {{ formatCurrency(item.priceTva.toFixed(2)) }}
            #simulation-charge.d-flex.w-100.justify-content-between.border-bottom.border-grey(v-if='sumLeaseModel.totalDocument.tvaDetails.length > 1')
              .text-left.flex Total TVA
              .text-right {{ formatCurrency(sumLeaseModel.totalDocument.totalTva.toFixed(2)) }}
            #simulation-charge.d-flex.w-100.justify-content-between.border-bottom.border-top-secondary.border-bottom-secondary
              .text-left.flex.font-weight-bold Total TTC
              .text-right.font-weight-bold {{ formatCurrency(sumLeaseModel.totalDocument.totalTtc) }}
        div(v-else)
          .p-2.text-center
            | Veuillez indiquer tous les paramètres financier de votre bail pour obtenir une simulation de facture
  vs-divider.mb-50.mt-1(color='#cccccc')
  b-row.m-0
    b-col(cols='12' style='padding-bottom:0px !important')
      h4 Révision du loyer
  b-row.m-0
    b-col(cols='12' style='padding-bottom:0px !important')
      b-form-group
        b-form-checkbox.mr-0.mt-50.font-weight-bold(v-model='leaseForm.isRentReview' name='is-rtl' switch='' inline='') Activer la révision du loyer
      label
        | En activant cette option, le montant du loyer sera révisé automatiquement à chaque période de révision.
  b-form-group.w-100.pt-1.px-1
    label(label-for='subscriptReferenceRentReview' style='font-weight: 600;') Type de révision
    b-form-radio-group#subscriptReferenceRentReview(v-model='leaseForm.isSubscriptReferenceRentReview' name='subscriptReferenceRentReview' :options='subscriptReferenceRentReviewOptions' text-field='label')
  .d-flex.inputs-wrapper.w-100.px-1(v-if='leaseForm.isRentReview')
    validation-provider.w-100.pt-1.mr-1(#default='{ errors }' name='rentReviewSubScriptType')
      label(label-for='rentReviewSubScriptType' style='font-weight: 600;') Type d'indice
      ejs-dropdownlist(id='rentReviewSubScriptType' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'value' }" :dataSource='reviewSubScriptTypeList' v-model='leaseForm.rentReviewSubScriptType')
    validation-provider.w-100.pt-1.mr-1(#default='{ errors }' name='rentReviewSubScript')
      label(label-for='rentReviewSubScript' style='font-weight: 600;') Indice
      ejs-dropdownlist(id='rentReviewSubScript' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'value' }" :dataSource='rentReviewSubScriptList' v-model='leaseForm.rentReviewSubScript')
    validation-provider.w-100.pt-1.mr-1(#default='{ errors }' name='rentReviewPercentage')
      label(label-for='rentReviewPercentage' style='font-weight: 600;') Pourcentage de révision
      ejs-numerictextbox(id="rentReviewPercentage" floatLabelType="Auto"  :step="step" :min="min" :max="max" format="###.## %" v-model="leaseForm.rentReviewPercentage")
    validation-provider.w-100.pt-1(#default='{ errors }' name='rentReviewPeriodicity')
      label(label-for='rentReviewPeriodicity' style='font-weight: 600;') Périodicité
      ejs-dropdownlist(id='rentReviewPeriodicity' :footerTemplate="'footerTemplate'" popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'id' }" :dataSource='periodicitiesList' v-model='leaseForm.rentReviewPeriodicityId')
        template(v-slot:footerTemplate)
          div.p-50
            ejs-button(v-on:click.native="newPeriodicity" :content="'+ Nouvelle périodicité'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
  vs-divider.mb-50.mt-1(color='#cccccc')
  .d-flex.flex-column.w-100.px-1.inputs-wrapper
    h4 Dépôt de garantie
    .d-flex.w-100
      .d-flex.w-100.mr-1
        .d-flex.w-75
          validation-provider.w-100(#default='{ errors }' name='securityDeposit')
            label(label-for='securityDeposit' style='font-weight: 600;') Dépot de garantie
            ejs-numerictextbox(id="securityDeposit" format="########.## €" v-model="leaseForm.securityDeposit")
        .d-flex.w-25.ml-1
          validation-provider(#default='{ errors }' name='tvaSecurityDepositId')
            label(label-for='tvaSecurityDepositId' style='font-weight: 600;') {{ leaseForm.securityDeposit ? 'TVA *' : 'TVA' }}
            ejs-dropdownlist(id='tvaSecurityDepositId' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'id' }" :dataSource=`institutionSelected.isSubjectTVA ? referencielTvaOptions : [{label:"0 %", id:5}]` v-model='leaseForm.tvaSecurityDepositId')
            small.text-danger {{ errors[0] }}
      .hide-in-mobile.w-100
  b-row.m-0
    b-col(cols='12' style='padding-bottom:0px !important')
      b-form-group
        b-form-checkbox.mr-0.mt-50.font-weight-bold(v-model='leaseForm.isSecurityDepositReview' name='is-rtl' switch='' inline='') Activer la révision du dép&ocirc;t de garantie
  .d-flex.flex-column.w-100.p-1.inputs-wrapper(v-if='leaseForm.isSecurityDepositReview')
    .d-flex.w-100
      .d-flex.w-100.mr-1
        validation-provider.w-100(#default='{ errors }' name='securityDepositReviewPeriodicity')
          label(label-for='securityDepositReviewPeriodicity' style='font-weight: 600;') Périodicité de la révision
          ejs-dropdownlist(id='securityDepositReviewPeriodicity' popupHeight='300px' :allowFiltering='true' :fields="{ text: 'label', value: 'id' }" :dataSource='periodicitiesList' v-model='leaseForm.securityDepositReviewPeriodicityId')
            template(v-slot:footerTemplate)
              div.p-50
                ejs-button(v-on:click.native="newPeriodicity" :content="'+ Nouvelle périodicité'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
      .hide-in-mobile.w-100
</template>

<script>
var convert = require("xml-js");
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import axios from "axios";
import { debounce } from "lodash";
import Autocomplete from "vuejs-auto-complete";
import { ECivility } from "@/types/api-orisis/enums/enums";
import Ripple from "vue-ripple-directive";
import {
  referencielTvaTranslate,
  civilityTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";
import { affairStatus } from "@/types/api-orisis/enums/enums";
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";

export default {
  name: "editLeaseGeneral",
  data() {
    return {
      statusList: affairStatus,
      displayGps: false,
      isCreatingLease: false,
      differentOwnerAddress: false,
      tokenEudonet: "",
      searchLandlordContact: "",
      searchTenantContact: "",
      autocompletePropertyData: [],
      autocompleteLandlordData: [],
      autocompleteLandlordContactData: [],
      autocompleteTenantData: [],
      autocompleteTenantContactData: [],
      step: 0.01,
      min: 0,
      max: 1,

      paymentDayList: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28,
      ],
      chargesPackageOptions: [
        { label: "Provisions pour charges", value: false },
        { label: "Forfait de charges", value: true },
      ],
      subscriptReferenceRentReviewOptions: [
        { label: "Indice de référence", value: true },
        { label: "Pourcentage", value: false },
      ],
      reviewSubScriptTypeList: [
        { label: "ICC (Indice du Cout de la Construction)", value: "ICC" },
        { label: "ILC (Indice des Loyers Commerciaux)", value: "ILC" },
        {
          label: "ILAT (Indice des Loyers des Activités Tertiaires)",
          value: "ILAT",
        },
        { label: "IRL (Indice de référence des loyers)", value: "IRL" },
      ],
      rentReviewSubScriptList: [],
      required,
      url,
      email,
      displayMore: false,
      leaseType: {},
      tenantList: [],
      landlordList: [],
      optionCivility: [
        {
          label: "M. et Mme",
          value: ECivility.MonsieurMadame,
        },
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },

        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
    };
  },
  props: {
    leaseForm: {
      type: Object,
      required: true,
    },
  },
  watch: {
    "leaseForm.ownAddressLease": function (newVal, oldVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.deteleAutocomplete();
        });
      }
    },
    "leaseForm.rentReviewSubScriptType": function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.leaseForm.rentReviewSubScript = "";
        if (newVal == "ILC") {
          this.getDataInseeILC();
        } else if (newVal == "ILAT") {
          this.getDataInseeILAT();
        } else if (newVal == "ICC") {
          this.getDataInseeICC();
        } else if (newVal == "IRL") {
          this.getDataInseeIRL();
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "workspaceSelected",
      "institutionSelected",
      "institutionSettingsActive",
      "isLoadingLease",
      "collaboratorsList",
      "contactsList",
      "companiesList",
      "periodicitiesList",
      "propertyTypeList",
      "leaseTypeList",
      "sumLeaseModel",
    ]),
    referencielTvaOptions() {
      return this.institutionSettingsActive.referencielTvas.map((elem) => {
        return { label: elem.referencielTva.label, id: elem.referencielTva.id };
      });
    },
  },
  async created() {
    this.getPeriodicities({});
    this.getTokenEudonet();

    await this.getCompanies({
      companyTypeId: 6,
      updateState: false,
    }).then((res) => {
      // A VERIFIER (BAP) - Vérifier le retour de res
      this.landlordList = res;
    });
    await this.getCompanies({
      companyTypeId: 7,
    }).then((res) => {
      // A VERIFIER (BAP) - Vérifier le retour de res
      this.tenantList = res;
    });
    this.leaseForm.periodicityId = this.leaseForm.periodicity.id;
  },
  async mounted() {
    this.deteleAutocomplete();
    await this.popupLeaseEvent();
  },
  methods: {
    ...mapActions([
      "calculateSumLease",
      "getCompanies",
      "updateAffair",
      "getPeriodicities",
      "createLease",
      "updateLease",
      "archiveLease",
      "createCompany",
      "createContact",
      "leaseGroupFunctions",
    ]),
    formatCurrency,
    referencielTvaTranslate,
    civilityTranslate,
    leaseGroupFunctionsLocal(args) {
      this.leaseGroupFunctions({
        lease: { ...this.leaseForm, periodicityId: args.itemData.id },
      });
    },
    resetTenant() {
      this.leaseForm.tenantId = null;
      this.leaseForm.tenantName = null;
      this.leaseForm.tenantCivility = null;
      this.leaseForm.tenantFirstName = null;
      this.leaseForm.tenantLastName = null;
      this.leaseForm.tenantAddress = null;
      this.leaseForm.tenantAddressComplement = null;
      this.leaseForm.tenantZipCode = null;
      this.leaseForm.tenantCity = null;
      this.leaseForm.tenantCountry = null;
      this.leaseForm.tenantContactId = null;
      this.leaseForm.tenantContactCivility = null;
      this.leaseForm.tenantContactLastName = null;
      this.leaseForm.tenantContactFirstName = null;
      this.leaseForm.tenantContactPhone = null;
      this.leaseForm.tenantContactEmail = null;
    },
    deteleAutocomplete() {
      let elements = document.querySelectorAll(".not-autocomplete input");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
      elements = document.querySelectorAll(".not-autocomplete");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
    },
    async popupLeaseEvent() {
      if (this.leaseForm.rentReviewSubScriptType == "ILC") {
        await this.getDataInseeILC();
      } else if (this.leaseForm.rentReviewSubScriptType == "ILAT") {
        await this.getDataInseeILAT();
      } else if (this.leaseForm.rentReviewSubScriptType == "ICC") {
        await this.getDataInseeICC();
      } else if (this.leaseForm.rentReviewSubScriptType == "IRL") {
        await this.getDataInseeIRL();
      }
      this.leaseGroupFunctions({ lease: this.leaseForm });
    },
    newPropertyType() {
      this.$router.push({ path: "/billing/property-types" });
    },
    newLeaseType() {
      this.$router.push({ path: "/billing/lease-types" });
    },
    newPeriodicity() {
      this.$router.push({ path: "/billing/periodicities" });
    },
    async saveLease(redirectTo = false) {
      const isValid = await this.$refs.editLeaseGeneralForm.validate();
      if (!isValid) {
        this.$bvToast.toast("Veuillez remplir les champs obligatoires", {
          title: "Erreur",
          variant: "danger",
          solid: true,
        });
        return;
      }
      // Si il n'existe pas créé le contact locataire
      if (
        (this.leaseForm.tenantContactFirstName ? true : false) &&
        (this.leaseForm.tenantContactLastName ? true : false) &&
        !this.leaseForm.tenantContactId
      ) {
        let tenantContact = {
          id: 0,
          civility: this.leaseForm.tenantContactCivility,
          lastName: this.leaseForm.tenantContactLastName,
          firstName: this.leaseForm.tenantContactFirstName,
          phoneNumber: this.leaseForm.tenantContactPhone,
          secondaryPhoneNumber: null,
          email: this.leaseForm.tenantContactEmail,
          activity: null,
          collaboratorId: null,
          institutionId: this.institutionSelected.id,
        };
        await this.createContact({ contact: tenantContact }).then(
          async (resTenantContact) => {
            this.leaseForm.tenantContactId = resTenantContact.id;
          }
        );
      }

      // Si il n'existe pas créé le locataire dans la base ORISIS
      if (
        (this.leaseForm.tenantName ? true : false) &&
        !this.leaseForm.tenantId
      ) {
        let tenant = {
          id: 0,
          addresses: [
            {
              address: this.leaseForm.tenantAddress
                ? this.leaseForm.tenantAddress
                : null,
              addressComplement: this.leaseForm.tenantAddressComplement
                ? this.leaseForm.tenantAddressComplement
                : null,
              zipCode: this.leaseForm.tenantZipCode
                ? this.leaseForm.tenantZipCode
                : null,
              city: this.leaseForm.tenantCity
                ? this.leaseForm.tenantCity
                : null,
              country: this.leaseForm.tenantCountry
                ? this.leaseForm.tenantCountry
                : null,
              label: this.leaseForm.tenantName
                ? this.leaseForm.tenantName
                : null,
              isDefault: true,
              // latitude: this.leaseForm.tenantLatitude
              //   ? this.leaseForm.tenantLatitude
              //   : 0,
              // longitude: this.leaseForm.tenantLongitude
              //   ? this.leaseForm.tenantLongitude
              //   : 0,
              id: 0,
            },
          ],
          contacts: this.leaseForm.tenantContactId
            ? [
                {
                  contactId: this.leaseForm.tenantContactId,
                  label: "Contact principal",
                },
              ]
            : [],
          color: null,
          email: null,
          phoneNumber: null,
          secondaryPhoneNumber: null,
          tvaNumber: null,
          isParticular: this.leaseForm.tenantIsParticular,
          name: this.leaseForm.tenantName ? this.leaseForm.tenantName : null,
          civility: this.leaseForm.tenantCivility,
          lastName: this.leaseForm.tenantLastName
            ? this.leaseForm.tenantLastName
            : null,
          firstName: this.leaseForm.tenantFirstName
            ? this.leaseForm.tenantFirstName
            : null,
          notes: null,
          companyAccount: this.leaseForm.tenantCompanyAccount
            ? this.leaseForm.tenantCompanyAccount
            : null,
          siret: this.leaseForm.tenantSiret ? this.leaseForm.tenantSiret : null,
          companyTypeId: 7,
          companyType: { name: "locataires", id: "7" },
          apeCode: null,
          rcsCode: null,
          source: null,
        };
        await this.createCompany({ company: tenant }).then(
          async (resTenant) => {
            this.leaseForm.tenantId = resTenant.id;
          }
        );
      }

      // Si il n'existe pas créé le contact propriétaire
      if (
        (this.leaseForm.landlordContactFirstName ? true : false) &&
        (this.leaseForm.landlordContactLastName ? true : false) &&
        !this.leaseForm.landlordContactId
      ) {
        let landordLordContact = {
          id: 0,
          civility: this.leaseForm.landlordContactCivility,
          lastName: this.leaseForm.landlordContactLastName
            ? this.leaseForm.landlordContactLastName
            : null,
          firstName: this.leaseForm.landlordContactFirstName
            ? this.leaseForm.landlordContactFirstName
            : null,
          phoneNumber: this.leaseForm.landlordContactPhone
            ? this.leaseForm.landlordContactPhone
            : null,
          secondaryPhoneNumber: null,
          email: this.leaseForm.landlordContactEmail,
          activity: null,
          collaboratorId: null,
          institutionId: this.institutionSelected.id,
        };
        await this.createContact({ contact: landordLordContact }).then(
          async (resLandlord) => {
            this.leaseForm.landlordContactId = resLandlord.id;
          }
        );
      }

      // Mise à jour de l'affaire associée au bail
      let affairForm = {
        id: this.leaseForm.affairId,
        name: this.leaseForm.label,
        description: null,
        startDate: this.leaseForm.startDate ? this.leaseForm.startDate : null,
        endDate: this.leaseForm.endDate ? this.leaseForm.endDate : null,
        collaboratorIds: [],
        code: this.leaseForm.codeCRO ? this.leaseForm.codeCRO : null,
        address: this.leaseForm.propertyAddress
          ? this.leaseForm.propertyAddress
          : null,
        addressComplement: this.leaseForm.propertyAddressComplement
          ? this.leaseForm.propertyAddressComplement
          : null,
        zipCode: this.leaseForm.propertyZipCode
          ? this.leaseForm.propertyZipCode
          : null,
        city: this.leaseForm.propertyCity ? this.leaseForm.propertyCity : null,
        country: this.leaseForm.propertyCountry
          ? this.leaseForm.propertyCountry
          : null,
        ownAddressAffair: false,
        companyId: this.leaseForm.tenantId ? this.leaseForm.tenantId : null,
        latitude: this.leaseForm.propertyLatitude
          ? this.leaseForm.propertyLatitude
          : 0,
        longitude: this.leaseForm.propertyLongitude
          ? this.leaseForm.propertyLongitude
          : 0,
        affairTypeId: 1,
        status: this.leaseForm.status,
      };

      await this.updateAffair({ affair: affairForm, route: "affair" }).then(
        async (res) => {
          this.leaseForm.affairId = res.id;
          await this.updateLease({
            lease: { ...this.leaseForm, propertyName: null },
            updateState: false,
          });
        }
      );
      if (redirectTo) {
        this.$tabs.close({ to: "/leases" });
      }
    },
    getTokenEudonet() {
      let url = `https://dev.lhotellier.orisis.fr/api-eudonet.php?request=authenticate`;
      axios
        .get(url)
        .then((res) => {
          this.tokenEudonet = encodeURI(res.data);
        })
        .catch((err) => {
          console.error("tokenEudonet error", err);
        });
    },
    // Data From INSEE
    async getDataInseeILC() {
      let _this = this;
      let urlILC = `https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/001532540`;
      await axios
        .get(urlILC, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_INSEE_KEY}`,
          },
        })
        .then((res) => {
          var result = convert.xml2json(res.data, {
            ignoreComment: true,
            alwaysChildren: true,
          });
          _this.rentReviewSubScriptList = JSON.parse(
            result
          ).elements[0].elements[1].elements[0].elements.map((elem) => {
            return {
              label:
                elem.attributes.TIME_PERIOD + " - " + elem.attributes.OBS_VALUE,
              value: elem.attributes.OBS_VALUE,
            };
          });
        })
        .catch((err) => {
          console.error("insee error", err);
        });
    },
    async getDataInseeILAT() {
      let _this = this;
      let urlILAT = `https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/001617112`;
      await axios
        .get(urlILAT, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_INSEE_KEY}`,
          },
        })
        .then((res) => {
          var result = convert.xml2json(res.data, {
            ignoreComment: true,
            alwaysChildren: true,
          });
          _this.rentReviewSubScriptList = JSON.parse(
            result
          ).elements[0].elements[1].elements[0].elements.map((elem) => {
            return {
              label:
                elem.attributes.TIME_PERIOD + " - " + elem.attributes.OBS_VALUE,
              value: elem.attributes.OBS_VALUE,
            };
          });
        })
        .catch((err) => {
          console.error("insee error", err);
        });
    },
    async getDataInseeICC() {
      let _this = this;
      let urlICC = `https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/000008630`;
      await axios
        .get(urlICC, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_INSEE_KEY}`,
          },
        })
        .then((res) => {
          var result = convert.xml2json(res.data, {
            ignoreComment: true,
            alwaysChildren: true,
          });
          _this.rentReviewSubScriptList = JSON.parse(
            result
          ).elements[0].elements[1].elements[0].elements.map((elem) => {
            return {
              label:
                elem.attributes.TIME_PERIOD + " - " + elem.attributes.OBS_VALUE,
              value: elem.attributes.OBS_VALUE,
            };
          });
        })
        .catch((err) => {
          console.error("insee error", err);
        });
    },
    async getDataInseeIRL() {
      let _this = this;
      let urlIRL = `https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/001515333`;
      await axios
        .get(urlIRL, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_INSEE_KEY}`,
          },
        })
        .then((res) => {
          var result = convert.xml2json(res.data, {
            ignoreComment: true,
            alwaysChildren: true,
          });
          _this.rentReviewSubScriptList = JSON.parse(
            result
          ).elements[0].elements[1].elements[0].elements.map((elem) => {
            return {
              label:
                elem.attributes.TIME_PERIOD + " - " + elem.attributes.OBS_VALUE,
              value: elem.attributes.OBS_VALUE,
            };
          });
        })
        .catch((err) => {
          console.error("insee error", err);
        });
    },
    autocompleteLandlordContact: debounce(async function (indexSource) {
      let data = [];
      let dataOutput = [];

      /* ICI SPECIAL LHOTELLIER */
      if (this.workspaceSelected.id == 14) {
        // Fetch Eudonet
        let dataEudonet = [];
        let paramsEudonet = {
          request: "searchContact",
          q: this.searchLandlordContact,
          token: this.tokenEudonet,
        };
        await axios
          .post(
            "https://dev.lhotellier.orisis.fr/api-eudonet.php",
            paramsEudonet,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((res) => {
            dataEudonet = Array.isArray(res.data)
              ? res.data.reduce((acc, current) => {
                  const x = acc.find(
                    (item) =>
                      item.civility === current.civility &&
                      item.email === current.email &&
                      item.lastName === current.lastName &&
                      item.phoneNumber === current.phoneNumber &&
                      item.secondaryPhoneNumber === current.secondaryPhoneNumber
                  );
                  if (!x) {
                    return acc.concat([current]);
                  } else {
                    return acc;
                  }
                }, [])
              : [];
          })
          .catch((err) => {
            console.error("autocompleteLandlord error", err);
          });
        data = dataEudonet;
      }

      dataOutput = this.contactsList.filter(
        (el) =>
          el.lastName
            .toLowerCase()
            .includes(this.searchLandlordContact.toLowerCase()) ||
          el.firstName
            .toLowerCase()
            .includes(this.searchLandlordContact.toLowerCase())
      );
      data.forEach((current) => {
        if (
          !dataOutput.find(
            (item) =>
              item.email === current.email &&
              item.lastName === current.lastName &&
              item.phoneNumber === current.phoneNumber
          )
        ) {
          dataOutput.push(current);
        }
      });
      this.autocompleteLandlordContactData = dataOutput;
    }, 200),
    autocompleteLandlordContactSelected(item) {
      this.leaseForm.landlordContactCivility = item.civility
        ? isNaN(item.civility)
          ? this.translateEudonetCivility(item.civility)
          : item.civility
        : 0;
      this.leaseForm.landlordContactLastName = item.lastName;
      this.leaseForm.landlordContactFirstName = item.firstName;
      this.leaseForm.landlordContactPhone = item.phoneNumber;
      this.leaseForm.landlordContactEmail = item.email;
      this.leaseForm.landlordContactId = item.id;
    },
    // Autocomplete Tenant from eudonet
    autocompleteTenant: debounce(async function (indexSource) {
      let data = [];
      let dataOutput = [];
      let dataOutputOrigine = [];
      let dataOutputFixed = [];
      let dataGouv = [];
      if (this.leaseForm.tenantName.trim().length > 2) {
        // Fetch Data.Gouv
        if (!this.leaseForm.tenantIsParticular) {
          await fetch(
            `https://recherche-entreprises.api.gouv.fr/search?q=${this.leaseForm.tenantName}&page=1&per_page=25`
          )
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              dataGouv = data.results.map((elem) => {
                let dataFormat = {
                  name: elem.nom_complet,
                  addresses: [
                    {
                      address: elem.siege.numero_voie
                        ? elem.siege.numero_voie + " " + elem.siege.libelle_voie
                        : elem.siege.libelle_voie,
                      addressComplement: elem.siege.complement_adresse
                        ? elem.siege.complement_adresse
                        : null,
                      zipCode: elem.siege.code_postal,
                      city: elem.siege.libelle_commune,
                      country: "France",
                    },
                  ],
                  companyAccount: null,
                  siret: elem.siege.siret,
                  id: 0,
                  source: "gouv",
                };
                return dataFormat;
              });
            });
          data = dataGouv;

          /* ICI SPECIAL LHOTELLIER */
          if (this.workspaceSelected.id == 14) {
            // Fetch Eudonet
            let dataEudonet = [];
            let paramsEudonet = {
              request: "searchCompany",
              q: this.leaseForm.tenantName,
              token: this.tokenEudonet,
            };
            await axios
              .post(
                "https://dev.lhotellier.orisis.fr/api-eudonet.php",
                paramsEudonet,
                { headers: { "Content-Type": "application/json" } }
              )
              .then((res) => {
                dataEudonet = res.data;
              })
              .catch((err) => {
                console.error("autocompleteTenant error", err);
              });
            data = dataGouv.concat(dataEudonet);
          }
        }
        dataOutputOrigine = this.tenantList.filter((el) => {
          return (
            el.name
              .toLowerCase()
              .includes(this.leaseForm.tenantName.toLowerCase()) &&
            el.isParticular == this.leaseForm.tenantIsParticular
          );
        });
      }
      dataOutput = dataOutputFixed = structuredClone(dataOutputOrigine);
      data.forEach((current) => {
        if (!dataOutputFixed.find((item) => item.name == current.name)) {
          dataOutput.push(current);
        }
      });
      this.autocompleteTenantData = dataOutput;
    }, 200),
    autocompleteTenantSelected(item) {
      this.leaseForm.tenantName = item.name;
      this.leaseForm.tenantLastName = item.lastName;
      this.leaseForm.tenantFirstName = item.firstName;
      this.leaseForm.tenantCivility = item.civility ? item.civility : 0;
      this.leaseForm.tenantAddress =
        item.addresses.length > 0 ? item.addresses[0].address : null;
      this.leaseForm.tenantAddressComplement =
        item.addresses.length > 0 ? item.addresses[0].addressComplement : null;
      this.leaseForm.tenantZipCode =
        item.addresses.length > 0 ? item.addresses[0].zipCode : null;
      this.leaseForm.tenantCity =
        item.addresses.length > 0 ? item.addresses[0].city : null;
      this.leaseForm.tenantCountry =
        item.addresses.length > 0 ? item.addresses[0].country : null;
      this.leaseForm.tenantCompanyAccount = item.companyAccount;
      this.leaseForm.tenantSiret = item.siret;
      this.leaseForm.tenantId = item.id;
    },
    autocompleteTenantContact: debounce(async function (indexSource) {
      let data = [];
      let dataOutput = [];

      /* ICI SPECIAL LHOTELLIER */
      if (this.workspaceSelected.id == 14) {
        // Fetch Eudonet
        let dataEudonet = [];
        let paramsEudonet = {
          request: "searchContact",
          q: this.searchTenantContact,
          token: this.tokenEudonet,
        };
        await axios
          .post(
            "https://dev.lhotellier.orisis.fr/api-eudonet.php",
            paramsEudonet,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((res) => {
            dataEudonet = Array.isArray(res.data)
              ? res.data.reduce((acc, current) => {
                  const x = acc.find(
                    (item) =>
                      item.civility === current.civility &&
                      item.email === current.email &&
                      item.lastName === current.lastName &&
                      item.phoneNumber === current.phoneNumber &&
                      item.secondaryPhoneNumber === current.secondaryPhoneNumber
                  );
                  if (!x) {
                    return acc.concat([current]);
                  } else {
                    return acc;
                  }
                }, [])
              : [];
          })
          .catch((err) => {
            console.error("autocompleteLandlord error", err);
          });
        data = dataEudonet;
      }
      dataOutput = this.contactsList.filter(
        (el) =>
          el.lastName
            .toLowerCase()
            .includes(this.searchTenantContact.toLowerCase()) ||
          el.firstName
            .toLowerCase()
            .includes(this.searchTenantContact.toLowerCase())
      );
      data.forEach((current) => {
        if (
          !dataOutput.find(
            (item) =>
              item.email === current.email &&
              item.lastName === current.lastName &&
              item.phoneNumber === current.phoneNumber
          )
        ) {
          dataOutput.push(current);
        }
      });
      this.autocompleteTenantContactData = dataOutput;
    }, 200),
    autocompleteTenantContactSelected(item) {
      this.leaseForm.tenantContactCivility = item.civility
        ? isNaN(item.civility)
          ? this.translateEudonetCivility(item.civility)
          : item.civility
        : 0;
      this.leaseForm.tenantContactLastName = item.lastName;
      this.leaseForm.tenantContactFirstName = item.firstName;
      this.leaseForm.tenantContactPhone = item.phoneNumber;
      this.leaseForm.tenantContactEmail = item.email;
      this.leaseForm.tenantContactId = item.id;
    },
    translateEudonetCivility(civility) {
      switch (civility) {
        case "Monsieur":
          return 0;
          break;

        case "Madame":
          return 1;
          break;

        case "Mademoiselle":
          return 1;
          break;

        default:
          return 2;
          break;
      }
    },
    // Autocomplete Address from data.gouv
    autocompleteAddressSource: debounce(function (indexSource) {
      if (this.leaseForm.propertyAddress.trim().length > 2) {
        fetch(
          `https://api-adresse.data.gouv.fr/search/?q=${this.leaseForm.propertyAddress}&autocomplete=0&limit=25`
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.autocompletePropertyData = data.features;
          });
      }
    }, 200),
    autocompleteAddressSelected(item) {
      this.leaseForm.propertyAddress = item.properties.name;
      this.leaseForm.propertyCity = item.properties.city;
      this.leaseForm.propertyZipCode = item.properties.postcode;
      this.leaseForm.propertyCountry = "FRANCE";
      this.leaseForm.propertyLatitude = item.geometry.coordinates[1];
      this.leaseForm.propertyLongitude = item.geometry.coordinates[0];
    },
    isInt(n) {
      return Number(n) === n && n % 1 === 0;
    },
    archiveLeaseLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveLease(id);
            this.$tabs.close();
          }
        });
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    Autocomplete,
  },
  directives: {
    Ripple,
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .inputs-wrapper {
    flex-direction: column;

    & .mr-1 {
      margin-right: 0 !important;
    }
  }
}
.hide-in-mobile {
  @media screen and (max-width: 750px) {
    display: none;
  }
}
</style>
