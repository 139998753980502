<template lang="pug">
  div.h-100.w-100
      .d-flex.flex-column
        #nav-sticky-horizontal.d-flex.justify-content-between.align-items-center.w-100.p-1
          ul.d-flex.space-between.w-100(style="height: 32px; overflow: hidden")
            li
              a.h-100.p-0.mr-1.d-flex.align-center.justify-content-center.align-items-center.w-100(:class="tabActive == 'general' ? 'current' : ''" @click="tabActive = 'general'")
                span Général
            li
              a.h-100.p-0.mr-1.d-flex.align-center.justify-content-center.align-items-center.w-100(:class="tabActive == 'internalNote' ? 'current' : ''" @click="tabActive = 'internalNote'")
                span Notes internes
                feather-icon.align-middle.ml-50(v-if="lease.internalNote" icon='MessageCircleIcon')
          div.d-flex.align-items-center.ml-auto
            ejs-dropdownbutton(cssClass="e-outline mr-1" :select="selectParamerterAction" :items="parametersActionList" iconCss="e-drpdwnbtn-icons e-btn-sb-icons e-icons e-settings"  :isPrimary="true" )
            div.e-btn-group.ml-1.e-lib.e-css
              ejs-button(v-on:click.native="saveLease(false)" content="Enregistrer" :isPrimary="true" locale='fr' style="border-top-right-radius: 0; border-bottom-right-radius: 0")
              ejs-button(v-on:click.native="saveLease(true)" :isPrimary="true" locale='fr' iconPosition="Right" style="border-left: 1px solid white; border-top-left-radius: 0; border-bottom-left-radius: 0")
                feather-icon(icon='LogOutIcon')
      .d-flex.flex-column.h-100.w-100(v-if='isLoadingLease || isCreatingLease || isUpdatingLease' style="height: calc(100% - 55px);")
          .text-center.flex-center
              .loading-bg-inner
                .loader
                  .outer
                  .middle
                  .inner
              .mt-5
                  br
                  br
                  br
                  |   {{ isLoadingLease ? 'Chargement des détails du bail...' : 'Enregistrement du bail...' }} 
      .d-flex.w-100.content-wrapper(v-else style="height: calc(100% - 61px); overflow-y: auto;")
        .d-flex.h-100.w-100(v-if="tabActive == 'general'")
          edit-lease-general(:leaseForm="leaseForm")
        .d-flex.h-100.w-100(v-else-if="tabActive == 'internalNote'")
          edit-lease-notes(:leaseForm="leaseForm")
  </template>
<script>
import { mapGetters, mapActions } from "vuex";

import vSelect from "vue-select";

import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
configure({
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import EditLeaseGeneral from "@/components/billing/lease/EditLeaseGeneral";
import EditLeaseNotes from "@/components/billing/lease/EditLeaseNotes";

export default {
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    EditLeaseGeneral,
    EditLeaseNotes,
  },
  data() {
    return {
      title: "Description",
      name: "leaseDescription",
      tabActive: "general",

      parametersActionList: [
        {
          text: "Archiver ce bail",
          iconCss: "e-btn-sb-icons e-icon-columns",
          type: "archive",
        },
      ],

      leaseForm: {},
    };
  },
  computed: {
    ...mapGetters([
      "isLoadingLease",
      "isCreatingLease",
      "isUpdatingLease",
      "lease",
      "institutionSelected",
      "affair",
    ]),
  },
  async created() {
    this.leaseForm = this.lease;
    this.dataOrigine = JSON.stringify(this.leaseForm);
  },
  methods: {
    ...mapActions([
      "createLease",
      "updateLease",
      "archiveLease",
      "createCompany",
      "updateAffair",
      "createContact",
    ]),
    archiveLeaseLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveLease(id);
            this.$tabs.close({ to: "/leases" });
            this.$bvToast.toast("Le bail a été archivé avec succès.", {
              title: "Bail archivé",
              variant: "success",
              solid: true,
            });
          }
        });
    },
    selectParamerterAction(args) {
      if (args.item.type == "archive") {
        this.archiveLeaseLocal(this.leaseForm.id, this.leaseForm.name);
      }
    },
    async saveLease(redirectTo = false) {
      if (
        this.$children.find((elem) => elem.$options.name == "editLeaseGeneral")
      ) {
        const isValid = await this.$children
          .find((elem) => elem.$options.name == "editLeaseGeneral")
          .$refs.editLeaseGeneralForm.validate();
        if (!isValid) {
          this.$bvToast.toast("Veuillez remplir les champs obligatoires", {
            title: "Erreur",
            variant: "danger",
            solid: true,
          });
          return;
        }
      }
      // Si il n'existe pas créé le contact locataire
      this.$store.commit("SET_IS_UPDATING_LEASE", true);
      if (
        (this.leaseForm.tenantContactFirstName ? true : false) &&
        (this.leaseForm.tenantContactLastName ? true : false) &&
        !this.leaseForm.tenantContactId
      ) {
        let tenantContact = {
          id: 0,
          civility: 0,
          lastName: this.leaseForm.tenantContactLastName,
          firstName: this.leaseForm.tenantContactFirstName,
          phoneNumber: this.leaseForm.tenantContactPhone,
          secondaryPhoneNumber: null,
          email: this.leaseForm.tenantContactEmail,
          activity: null,
          collaboratorId: null,
        };
        await this.createContact({ contact: tenantContact }).then(
          async (resLandlord) => {
            this.leaseForm.tenantContactId = resLandlord.id;
          }
        );
      }

      // Si il n'existe pas créé le locataire dans la base ORISIS
      if (
        (this.leaseForm.tenantName ? true : false) &&
        !this.leaseForm.tenantId
      ) {
        let tenant = {
          id: 0,
          addresses: [
            {
              address: this.leaseForm.tenantAddress
                ? this.leaseForm.tenantAddress
                : null,
              addressComplement: this.leaseForm.tenantAddressComplement
                ? this.leaseForm.tenantAddressComplement
                : null,
              zipCode: this.leaseForm.tenantZipCode
                ? this.leaseForm.tenantZipCode
                : null,
              city: this.leaseForm.tenantCity
                ? this.leaseForm.tenantCity
                : null,
              country: this.leaseForm.tenantCountry
                ? this.leaseForm.tenantCountry
                : null,
              label: this.leaseForm.tenantName
                ? this.leaseForm.tenantName
                : null,
              isDefault: true,
              latitude: this.leaseForm.tenantLatitude
                ? this.leaseForm.tenantLatitude
                : 0,
              longitude: this.leaseForm.tenantLongitude
                ? this.leaseForm.tenantLongitude
                : 0,
              id: 0,
            },
          ],
          contacts: this.leaseForm.tenantContact.tenantContactId
            ? [
                {
                  contactId: this.leaseForm.tenantContactId,
                  label: "Contact principal",
                },
              ]
            : [],
          color: null,
          email: null,
          phoneNumber: null,
          secondaryPhoneNumber: null,
          tvaNumber: null,
          isParticular: this.leaseForm.tenantIsParticular,
          name: this.leaseForm.tenantName ? this.leaseForm.tenantName : null,
          civility: 0,
          lastName: this.leaseForm.tenantLastName
            ? this.leaseForm.tenantLastName
            : null,
          firstName: this.leaseForm.tenantFirstName
            ? this.leaseForm.tenantFirstName
            : null,
          notes: null,
          companyAccount: this.leaseForm.tenantCompanyAccount
            ? this.leaseForm.tenantCompanyAccount
            : null,
          siret: this.leaseForm.tenantSiret ? this.leaseForm.tenantSiret : null,
          companyTypeId: 7,
          companyType: { name: "locataires", id: "7" },
          apeCode: null,
          rcsCode: null,
          source: null,
        };
        await this.createCompany({ company: tenant }).then(
          async (resTenant) => {
            this.leaseForm.tenantId = resTenant.id;
          }
        );
      } else if (this.leaseForm.tenantId) {
        // MISE A JOUR DE LA LIAISON DU CONTACT LOCATAIRE AVEC LE LOCATAIRE
      }

      // Si il n'existe pas créé le contact propriétaire
      if (
        (this.leaseForm.landlordContactFirstName ? true : false) &&
        (this.leaseForm.landlordContactLastName ? true : false) &&
        !this.leaseForm.landlordContactId
      ) {
        let landordLordContact = {
          id: 0,
          civility: 0,
          lastName: this.leaseForm.landlordContactLastName
            ? this.leaseForm.landlordContactLastName
            : null,
          firstName: this.leaseForm.landlordContactFirstName
            ? this.leaseForm.landlordContactFirstName
            : null,
          phoneNumber: this.leaseForm.landlordContactPhone
            ? this.leaseForm.landlordContactPhone
            : null,
          secondaryPhoneNumber: null,
          email: this.leaseForm.landlordContactEmail,
          activity: null,
          collaboratorId: null,
        };
        await this.createContact({ contact: landordLordContact }).then(
          async (resLandlord) => {
            this.leaseForm.landlordContactId = resLandlord.id;
          }
        );
      }

      // Mise à jour de l'affaire associée au bail
      let affairForm = {
        ...this.affair,
        name: this.leaseForm.label,
        startDate: this.leaseForm.startDate ? this.leaseForm.startDate : null,
        endDate: this.leaseForm.endDate ? this.leaseForm.endDate : null,
        code: this.leaseForm.codeCRO ? this.leaseForm.codeCRO : null,
        address: this.leaseForm.propertyAddress
          ? this.leaseForm.propertyAddress
          : null,
        addressComplement: this.leaseForm.propertyAddressComplement
          ? this.leaseForm.propertyAddressComplement
          : null,
        zipCode: this.leaseForm.propertyZipCode
          ? this.leaseForm.propertyZipCode
          : null,
        city: this.leaseForm.propertyCity ? this.leaseForm.propertyCity : null,
        country: this.leaseForm.propertyCountry
          ? this.leaseForm.propertyCountry
          : null,
        ownAddressAffair: false,
        companyId: this.leaseForm.tenantId ? this.leaseForm.tenantId : null,
        latitude: this.leaseForm.propertyLatitude
          ? this.leaseForm.propertyLatitude
          : 0,
        longitude: this.leaseForm.propertyLongitude
          ? this.leaseForm.propertyLongitude
          : 0,
        affairTypeId: 1,
        status: this.leaseForm.status,
        internalNote: this.leaseForm.internalNote,
      };

      await this.updateAffair({ affair: affairForm, route: "affair" }).then(
        async (res) => {
          this.leaseForm.affairId = res.id;
          await this.updateLease({
            lease: { ...this.leaseForm, propertyName: null },
            updateState: false,
          });
        }
      );
      if (redirectTo) {
        this.$tabs.close({ to: "/leases" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.e-btn {
  display: flex;
  align-items: center;
  height: 32px;
}
@media screen and (max-width: 859px) {
  #nav-sticky-horizontal {
    flex-direction: column-reverse;
    padding-bottom: 0 !important;

    & > div {
      justify-content: flex-start !important;
      width: 100%;
      padding-bottom: 1rem;
    }

    & a span {
      max-width: 70px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .content-wrapper {
    height: calc(100% - 93px) !important;
  }
}
</style>
