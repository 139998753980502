<template lang="pug">
  .p-0
    .content-loader-center.m-0.h-100(v-if='isLoadingLease')
      .text-center.flex-center
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement des détails du bail...
    div.details-container(v-else)
      div.d-flex.content-scrollable-sticky.h-100.justify-content-start.w-100
          #nav-sticky-vertical.text-center.h-100(ref="navStickyVertical" style='background: #EEF1F6; width: 240px; min-width: 240px; max-width: 240px;')
            .close-btn.d-flex.justify-content-start.pt-1.pr-1(style="padding-left: 0.8rem")
              span.material-icons-outlined(@click="closeMenuSidebar" style="cursor: pointer;")
                | chevron_left
            .head.p-0
              .d-flex.justify-content-between.px-1
                div
                  h3.text-nowrap Bail : {{lease.code}}
              .d-flex.justify-content-between.pb-1.px-1
                .d-flex
                  span.text-nowrap(style="white-space: break-spaces;") {{lease.label}}
                .end
            ul
              li.title
                vs-divider(color="#cccccc" class="m-0 mt-1 mb-50" position="left")
                  span.text-primary Dossier
              li
                a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('leaseDescription')" :class="tabActive == 'leaseDescription' ? 'current' : ''")
                  span.material-icons-outlined.text-primary
                    | assignment
                  span.pl-1.text-nowrap Description
              li(v-if="checkModule(5)")
                a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('ged')" :class="tabActive == 'ged' ? 'current' : ''")
                  span.material-icons-outlined.text-primary
                    | inbox
                  span.pl-1.text-nowrap Pièces/GED
              li.title
                vs-divider(color="#cccccc" class="m-0 mt-1 mb-50" position="left")
                  span.text-primary Documents
              li(v-if="checkModule(2)")
                a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('invoices')" :class="tabActive == 'invoices' ? 'current' : ''")
                  span.material-icons-outlined.text-primary
                    | description
                  span.pl-1.text-nowrap Factures
              li(v-if="checkModule(2)")
                a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('credits')" :class="tabActive == 'credits' ? 'current' : ''")
                  span.material-icons-outlined.text-primary
                    | indeterminate_check_box
                  span.pl-1.text-nowrap Avoirs
              li(v-if="checkModule(4)")
                a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('invoicesProvider')" :class="tabActive == 'invoicesProvider' ? 'current' : ''")
                  span.material-icons-outlined.text-primary
                    | description
                  span.pl-1.text-nowrap Factures fournisseur
              li(v-if="checkModule(4)")
                a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('creditsProvider')" :class="tabActive == 'creditsProvider' ? 'current' : ''")
                  span.material-icons-outlined.text-primary
                    | indeterminate_check_box
                  span.pl-1.text-nowrap Avoirs fournisseur
          #content-wrapper.d-flex.flex-column.h-100(style="width: calc(100% - 240px)" ref="contentWrapper")
            .h-100.w-100
              LeaseDescription(v-if="tabActive=='leaseDescription'")
              AffairInvoicesList(v-else-if="tabActive=='invoices'" :affairId='lease.affairId')
              AffairCreditsList(v-else-if="tabActive=='credits'" :affairId='lease.affairId')
              AffairInvoicesProviderList(v-else-if="tabActive=='invoicesProvider'" :affairId='lease.affairId')
              AffairCreditsProviderList(v-else-if="tabActive=='creditsProvider'" :affairId='lease.affairId')
              GedViewer(v-else-if="tabActive=='ged'" :fromModule="'affair'" :parentFolder='lease.folderId')
  
  
  </template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  BTable,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardBody,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import GedViewer from "@/components/ged/GedViewer";

import LeaseDescription from "@/components/billing/lease/LeaseDescription";

import AffairInvoicesList from "@/components/directory/affair/AffairInvoicesList";
import AffairCreditsList from "@/components/directory/affair/AffairCreditsList";
import AffairInvoicesProviderList from "@/components/directory/affair/AffairInvoicesProviderList";
import AffairCreditsProviderList from "@/components/directory/affair/AffairCreditsProviderList";

import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  components: {
    BTable,
    BFormGroup,
    BCardBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    VBTooltip,

    LeaseDescription,

    AffairInvoicesList,
    AffairCreditsList,
    AffairInvoicesProviderList,
    AffairCreditsProviderList,
    GedViewer,
  },
  props: {
    id: {
      default: null,
    },
  },
  watch: {
    async $route(to, from) {
      this.$destroy();
    },
  },
  async mounted() {
    if (this.id !== "" && this.id !== 0 && this.lease.id !== this.id) {
      await this.getLeaseById({
        leaseId: this.id,
      });
    }
    await this.getCompanies({});
    await this.getCollaborators({});
    await this.getLeaseTypes({});
  },
  data() {
    return {
      tabActive: "leaseDescription",
    };
  },
  computed: {
    ...mapGetters(["isLoadingLease", "workspaceSelected"]),
    lease: {
      get() {
        return this.$store.getters.lease;
      },
      set(value) {
        return this.$store.commit("SET_LEASE", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "getLeaseById",
      "getCompanies",
      "getCollaborators",
      "getLeaseTypes",
    ]),
    formatDate,
    activeTab(tab) {
      this.tabActive = tab;
    },
    checkModule(moduleId) {
      return true;
    },
    closeMenuSidebar() {
      this.$refs.navStickyVertical.classList.toggle("sidebar-closed");
      this.$refs.contentWrapper.classList.toggle("sidebar-is-closed");
    },
  },
};
</script>

<style lang="scss">
.con-vs-tabs .con-slot-tabs {
  height: 100%;
  .vs-tabs--content {
    padding: 0px;
    height: 100%;
    > div {
      padding: 0px;
      height: 100%;
    }
  }
}
.sidebar-closed,
.sidebar-closed .title span {
  transition-delay: 0.5s;
  transition: all 0.5s ease-in-out;
}

.sidebar-closed .title span {
  font-size: 0;
}
.sidebar-closed .title .vs-divider {
  margin: 0 !important;
}

.sidebar-closed .close-btn span {
  transform: rotate(180deg);
}

.sidebar-closed .head,
.sidebar-closed .title {
  animation: fadeOut 0.5s ease-in-out forwards;
}

.sidebar-closed {
  width: 52px !important;
  min-width: 52px !important;
  max-width: 52px !important;
}

#content-wrapper {
  transition: all 0.5s ease-in-out;
}

#content-wrapper.sidebar-is-closed {
  width: calc(100% - 52px) !important;
}

#nav-sticky-vertical,
.title span,
.head,
.title,
.title .vs-divider {
  transition: all 0.5s ease-in-out;
}

@keyframes fadeOut {
  from {
    max-height: 100%;
    width: 100%;
    opacity: 1;
    visibility: visible;
  }
  to {
    max-height: 0%;
    width: 0%;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
